import { FC } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { theme } from "../../../../theme";
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import { useTranslation } from "react-i18next";

const UserDetailsNotFound:FC = () => {
  const {t} = useTranslation();

  return (
    <>
        <Box sx={{p: theme.spacing(3), flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
          <Typography fontWeight="bold">{t('USER.NOTFOUND.HEADER')}</Typography>
        </Box>
        <Divider sx={{marginBottom: theme.spacing(2)}} />
        <Box sx={{p: theme.spacing(3), textAlign: 'center', height: '80vh', pt: theme.spacing(25)}}>
            <AssignmentLateOutlinedIcon sx={{fontSize: '150px'}}/>
            <Typography fontSize={theme.typography.h4.fontSize} sx={{mb: theme.spacing(3)}}>{t('USER.NOTFOUND.TITLE')}</Typography>
            <Typography>{t('USER.NOTFOUND.TEXT')}</Typography>
        </Box>
      </>
  );
}


export default UserDetailsNotFound;
import {FC, useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {Box, Button, Divider, Typography} from "@mui/material";
import UserDetailsTable from "./UserDetailsTab";
import UserCard from "../UserCard/UserCard";
import {theme} from "../../../../theme";
import UserDetailsNotFound from "./UserDetailsNotFound";
import {isDefined} from "../../../../utils/type-utils";
import {User} from "../../../../core/entities/User";
import {getUserByEmailOrId} from "../../../../core/usecases/getUserByEmailOrId";
import { banUser, unbanUser } from "../../../../application/services/UserService";
import ConfirmModal from "../../FloatingStuff/ConfirmModal";
import { useToaster } from "../../../../application/context/ToasterContext";
import { useTranslation } from "react-i18next";

type UserDetailsProps = {
    userToSearch?: string;
}

const UserDetails: FC<UserDetailsProps> = ({userToSearch}) => {
    const { t } = useTranslation();
    const toaster = useToaster();

    const [user, setUser] = useState<User>();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [modalText, setModalText] = useState(t('USER.BAN.CONFIRM'));

    const {isSuccess, isError, isPending, mutate} = useMutation({
        mutationFn: () => getUserByEmailOrId(userToSearch),
        onSuccess: (a) => {
            setUser(a);
        },
        onError: (a) => {
            // console.error(a)
        },
    });

    const banMutation = useMutation({
        mutationFn: (banTarget: User) => banUser(banTarget),
        onSuccess: (a) => {
            setUser(a);
            toaster.success(t('USER.BAN.TOASTER.SUCCESS'));
        },
        onError: (a) => {
            toaster.error(t('USER.BAN.TOASTER.ERROR.DEFAULT'))
        },
    });

    const unbanMutation = useMutation({
        mutationFn: (banTarget: User) => unbanUser(banTarget),
        onSuccess: (a) => {
            setUser(a);
            toaster.success(t('USER.UNBAN.TOASTER.SUCCESS'));
        },
        onError: (a) => {
            toaster.error(t('USER.UNBAN.TOASTER.ERROR.DEFAULT'));
        },
    });

    const handleConfirmModal = () => {
        setConfirmModalOpen(false);
        if (!isDefined(user)) {
            return;
        }

        if (!user?.isBanned) {
            banMutation.mutate(user);
        } else {
            unbanMutation.mutate(user);
        }
    }

    const handleBanUnBanButtons = (e: React.MouseEvent<HTMLElement>, modalText: string) => {
        e.preventDefault();
        setModalText(modalText);
        setConfirmModalOpen(true);
    }

    useEffect(() => {
        mutate();
    }, [mutate, userToSearch])

    return (
        <>
            {
                isPending && <Typography>is loading</ Typography>
            }
            {
                isError && <UserDetailsNotFound/>
            }
            {
                (isSuccess && isDefined(user)) &&
                <>
                    <Box sx={{
                        p: theme.spacing(3),
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <UserCard
                            src={user.profilePictureUrl}
                            topLineText={`${t('USER.AVATAR.PRETEXT')}: ${user.vcId}`}
                            bottomLineText={user.email}
                        />
                        {
                            user.isBanned ?
                                <Button
                                    onClick={(e) => handleBanUnBanButtons(e, t('USER.UNBAN.CONFIRM'))} 
                                    variant="outlined"
                                    disabled={unbanMutation.isPending}
                                >{t('USER.CTA.UNBAN')}</Button> :
                                <Button
                                    onClick={(e) => handleBanUnBanButtons(e, t('USER.BAN.CONFIRM'))}
                                    variant="outlined"
                                    color="error"
                                    disabled={banMutation.isPending}
                                >{t('USER.CTA.BAN')}</Button>
                        }
                    </Box>
                    <Divider sx={{marginBottom: theme.spacing(2)}}/>
                    <Box sx={{p: theme.spacing(3)}}>
                        <Typography sx={{textDecoration: "underline", fontWeight: "Bold"}}>
                            <span style={{cursor: "pointer"}}>{t('USER.TAB.PERSONALINFO')}</span>
                        </Typography>
                    </Box>
                    <Box sx={{p: theme.spacing(3)}}>
                        <UserDetailsTable userInfos={user}></UserDetailsTable>
                    </Box>
                    <ConfirmModal
                        open={confirmModalOpen}
                        text={modalText}
                        onCancel={() => {setConfirmModalOpen(false)}}
                        onConfirm={() => {handleConfirmModal()}}
                    />
                </>
            }
        </>
    );
}


export default UserDetails;
import { isDefined } from "../../utils/type-utils";

const headers = (accessToken: string) => ({
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
});

export const isUserAuthorized = async (accessToken: string): Promise<boolean> => {
    const papiUrl = process.env.REACT_APP_PAPI_URL;

    if (!papiUrl) {
        throw new Error('PAPI_URL is not defined');
    }
    const response = await fetch(`${papiUrl}/version`, {
        method: "GET",
        headers: headers(accessToken),
    });

    return response.status !== 403;
}

export const get = async (endpoint: string, accessToken: string): Promise<any> => {
    if (!process.env.REACT_APP_PAPI_URL) {
        throw new Error('PAPI_URL is not defined');
    }
    const response = await fetch(`${process.env.REACT_APP_PAPI_URL}${endpoint}`, {
        method: "GET",
        headers: headers(accessToken),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
}

export const post = async (endpoint: string, data: any, accessToken: string): Promise<any> => {
    if (!process.env.REACT_APP_PAPI_URL) {
        throw new Error('PAPI_URL is not defined');
    }
    const response = await fetch(`${process.env.REACT_APP_PAPI_URL}${endpoint}`, {
        method: "POST",
        headers: headers(accessToken),
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
}

export const put = async (endpoint: string, data: any, accessToken: string): Promise<any> => {
    if (!process.env.REACT_APP_PAPI_URL) {
        throw new Error('PAPI_URL is not defined');
    }
    const response = await fetch(`${process.env.REACT_APP_PAPI_URL}${endpoint}`, {
        method: "PUT",
        headers: headers(accessToken),
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
}

export const patch = async (endpoint: string, accessToken: string, data?: any, ): Promise<any> => {
    if (!process.env.REACT_APP_PAPI_URL) {
        throw new Error('PAPI_URL is not defined');
    }
    const response = await fetch(`${process.env.REACT_APP_PAPI_URL}${endpoint}`, {
        method: "PATCH",
        headers: headers(accessToken),
        body: isDefined(data) ? JSON.stringify(data) : "",
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
}
import { createContext, useContext, useEffect, useState } from 'react';
import { Auth, FCWithChildren } from '../../infrastructure/types/global';
import { authService } from '../services/auth/AuthService';
import { isDefined } from '../../utils/type-utils';

export type AuthContextProps = {
    auth: Auth | undefined;
    loginAction: Function;
    logOutAction: Function;
}

const AuthContext = createContext<AuthContextProps>({} as never);

export const AuthProvider: FCWithChildren = ({children}): React.JSX.Element => {
    const [auth, setAuth] = useState<Auth>();


    useEffect(() => {
        if(!(authService.auth.isAuthenticated && authService.auth.isAuthorized)) {
            authService.authorise();
        }

        setAuth(authService.auth);
    }, []);

    const loginAction = () => {
        if (!isDefined(process.env.REACT_APP_BACKOFFICE_URL)) {
            throw new Error('REACT_APP_BACKOFFICE_URL is not defined');
        }

        window.location.href = process.env.REACT_APP_BACKOFFICE_URL;
    };

    const logOutAction = () => {
        // Implement logout logic here
    };

    return (
        <AuthContext.Provider value={{ auth, loginAction, logOutAction }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => useContext(AuthContext);

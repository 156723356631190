import PKCE from "js-pkce";
import Cookies from "cookies-ts";
import { ENV_CONFIG } from "./AuthService.config";
import { getCookie } from "../../../infrastructure/persistence/CookieAdapter";
import { jwtDecode } from "jwt-decode";
import { Auth, IdToken } from "../../../infrastructure/types/global";
import { isDefined } from "../../../utils/type-utils";

export class AuthenticationService {
  private readonly COOKIE_ACCESS_TOKEN = "access_token";
  private readonly COOKIE_ID_TOKEN = "id_token";
  private readonly _cookies: Cookies;
  private readonly _auth: PKCE;

  constructor() {
    this._cookies = new Cookies();
    this._auth = this.initializePKCE();
  }

  private initializePKCE(): PKCE {
    const pKCE = new PKCE({
      client_id: ENV_CONFIG.oauthClientId,
      redirect_uri: ENV_CONFIG.oauthClientCallbackUrl,
      authorization_endpoint: `${ENV_CONFIG.oauthUrl}/oauth2/auth`,
      token_endpoint: `${ENV_CONFIG.oauthUrl}/oauth2/token`,
      requested_scopes: ENV_CONFIG.oauthClientScopes,
    });

    return pKCE;
  }

  private removeCookies() {
    this._cookies.remove(this.COOKIE_ID_TOKEN, {
      domain: ENV_CONFIG.cookiesDomain,
    });
    this._cookies.remove(this.COOKIE_ACCESS_TOKEN, {
      domain: ENV_CONFIG.cookiesDomain,
    });
  }

  private removeSessionStorage() {
    sessionStorage.removeItem(this.COOKIE_ID_TOKEN);
    sessionStorage.removeItem(this.COOKIE_ACCESS_TOKEN);
  }

  authorise() {
    if (!(isDefined(this.accessToken) && isDefined(this.idToken))) {
      window.location.replace(
        this._auth.authorizeUrl({
          state: Date.now().toString(),
        })
      );
    }
  }

  async handleCallback() {
    try {
      const token = await this._auth.exchangeForAccessToken(
        window.location.toString()
      );
      this.storeTokens(token);
    } catch (error) {
      console.error("Error exchanging code for tokens:", error);
    }
  }

  private storeTokens(token: any) {
    this._cookies.set(this.COOKIE_ACCESS_TOKEN, token.access_token, {
      expires: `${token.expires_in}s`,
      domain: ENV_CONFIG.cookiesDomain,
    });
    this._cookies.set(this.COOKIE_ID_TOKEN, token.id_token, {
      expires: `${token.expires_in}s`,
      domain: ENV_CONFIG.cookiesDomain,
    });

    sessionStorage.setItem(this.COOKIE_ACCESS_TOKEN, token.access_token);
    sessionStorage.setItem(this.COOKIE_ID_TOKEN, token.id_token);
  }

  logout() {
    this.removeCookies();
    this.removeSessionStorage();
  }

  get auth(): Auth {
    const accessToken = this.accessToken;
    const idToken = this.idToken;
  
    let decodedToken: IdToken | null = null;
    if (isDefined(idToken)) {
      try {
        decodedToken = jwtDecode<IdToken>(idToken);
      } catch (error) {
        console.error('Invalid token specified:', error);
      }
    }
  
    const isAuthenticated =
      isDefined(decodedToken) && decodedToken.exp
        ? new Date() <= new Date(decodedToken.exp * 1000)
        : false;
    const isAuthorized = isAuthenticated && isDefined(accessToken);
  
    return {
      isAuthenticated,
      accessToken,
      idToken: decodedToken,
      isAuthorized,
    };
  }

  private get idToken(): string | null {
    return getCookie(this.COOKIE_ID_TOKEN);
  }

  private get accessToken(): string | null {
    return getCookie(this.COOKIE_ACCESS_TOKEN);
  }
}

export const authService = new AuthenticationService();

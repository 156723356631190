import { alpha, createTheme } from '@mui/material';

// https://mui.com/material-ui/customization/palette/#generate-tokens-using-augmentcolor-utility
declare module '@mui/material/styles' {
  interface Palette {
    textBlackColor: Palette['primary'];
    scheduledStatusColor: Palette['primary'];
    processingStatusColor: Palette['primary'];
    processedStatusColor: Palette['primary'];
    notificationColor: Palette['primary'];
  }

  interface PaletteOptions {
    textBlackColor?: PaletteOptions['primary'];
    scheduledStatusColor?: Palette['primary'];
    processingStatusColor?: Palette['primary'];
    processedStatusColor?: Palette['primary'];
    notificationColor?: Palette['primary'];
  }
}

const font =  '"Helvetica Now Text", Helvetica, Arial, sans-serif';

export let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
  typography: {
    fontFamily: font,
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 700,
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: font,
        },
      }   
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: font,
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: font,
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: font,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontFamily: font,
          variants: [
            {
              props: { variant: 'outlined', color: 'primary' },
              style: {
                backgroundColor: 'rgba(25, 118, 210, 0.11)',
                "&:hover": {
                  backgroundColor: 'rgba(25, 118, 210, 0.21)',
                },
                "&:active": {
                  backgroundColor: 'rgba(25, 118, 210, 0.31)',
                }
              },
            },
            {
              props: { variant: 'outlined', color: 'error' },
              style: {
                backgroundColor: 'rgba(211, 47, 47, 0.11)',
                "&:hover": {
                  backgroundColor: 'rgba(211, 47, 47, 0.21)',
                },
                "&:active": {
                  backgroundColor: 'rgba(211, 47, 47, 0.31)',
                }
              },
            },
          ],
        }
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#FAFAFA",
        }
      }
    }
  },
  palette: {
    textBlackColor: theme.palette.augmentColor({
      color: {
        main: alpha('#000', 0.7)
      },
      name: 'textBlackColor',
    }),
    scheduledStatusColor: theme.palette.augmentColor({
      color: {
        main: alpha('#9c27b0cc', 0.12),
        contrastText: '#000'
      },
      name: 'scheduledStatusColor',
    }),
    processingStatusColor: theme.palette.augmentColor({
      color: {
        main: alpha('#0288d1cc', 0.12),
        contrastText: '#000'
      },
      name: 'processingStatusColor',
    }),
    processedStatusColor: theme.palette.augmentColor({
      color: {
        main: alpha('#2e7d32cc', 0.12),
        contrastText: '#000'
      },
      name: 'processedStatusColor',
    }),
    notificationColor: theme.palette.augmentColor({
      color: {
        main: '#F5F5F5',
        contrastText: '#000'
      },
      name: 'notificationColor',
    }),
  },
});



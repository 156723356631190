import {User} from "../../core/entities/User";
import {get, patch} from "../api/PapiClient";

export const getUserByEmail = async (value: string, accessToken: string): Promise<User> => {
    return get(`/users/email/${value}`, accessToken);
}

export const getUserById = async (value: string, accessToken: string): Promise<User> => {
    return get(`/users/vcId/${value}`, accessToken);
}

export const patchBanUser = async (userId: number, accessToken: string): Promise<User> => {
    return patch(`/users/${userId}/bans`, accessToken);
}

export const patchUnbanUser = async (userId: number, accessToken: string): Promise<User> => {
    return patch(`/users/${userId}/unbans`, accessToken);
}
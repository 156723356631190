import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { FC } from "react";
import { theme } from "../../../theme";
import { useTranslation } from "react-i18next";

type ConfirmModalProps = {
    text: string;
    open: boolean;
    onCancel: Function;
    onConfirm: Function;
}

const ConfirmModal:FC<ConfirmModalProps> = ({
    text,
    open,
    onCancel,
    onConfirm
}) => {
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={() => onCancel()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Close onClick={() => onCancel()} sx={{cursor: 'pointer', position: 'absolute', top: '4px', right: '4px'}}/>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{marginTop: theme.spacing(1)}}>
                    { text }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={() => onCancel()}>{t('COMFIRM.CTA.CANCEL')}</Button>
                <Button variant="outlined" onClick={() => onConfirm()}>{t('CONFIRM.CTA.OK')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal
import { FormHelperText, InputAdornment, OutlinedInput, SxProps, Theme } from "@mui/material";
import { KeyboardEvent, FC, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { searchType, typeToSearch } from "../../../../utils/search-utils";
import { theme } from "../../../../theme";
import { useTranslation } from "react-i18next";

type SearchBarProps = {
    sx?: SxProps<Theme>
}

const SearchBar:FC<SearchBarProps> = ({ sx }) => {
  const [valueToSearch, setValueToSearch] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setHasError(false)

      const trimedValue = valueToSearch.trim();
      switch(typeToSearch(trimedValue)) {
        case searchType.USER_ID:
        case searchType.EMAIL:
          navigate(`/users/${trimedValue}`);
          break;
        default:
          setHasError(true);
          break;
      }      
    }
  }

  const handleClear = () => {
    setValueToSearch('');
    setHasError(false);
  }

  return (
    <>
      <OutlinedInput
        sx={{...sx, p: theme.spacing(0, 1), background: theme.palette.grey[200], height: theme.spacing(6)}}
        id="search-field-sidebar"
        value={valueToSearch}
        error={hasError}
        onChange={(e) => setValueToSearch(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={t('SIDEBAR.SEARCHBAR.PLACEHOLDER')}
        startAdornment={(
          <InputAdornment position="start">
             <SearchIcon color={hasError ? 'error' : 'inherit'}/>
          </InputAdornment>
        )}
        endAdornment={
        valueToSearch.length > 0 &&
          <InputAdornment position="end">
            <Close onClick={() => handleClear()} sx={{cursor: 'pointer'}}/>
          </InputAdornment>
        }
      />
      <FormHelperText error={hasError}>
        {hasError ? t('SIDEBAR.SEARCHBAR.ERROR.EMAIL') : t('SIDEBAR.SEARCHBAR.SUBTEXT')}
      </FormHelperText>
    </>
  );
}


export default SearchBar;
import { User } from "../../core/entities/User";
import { getUserByEmail, getUserById, patchBanUser, patchUnbanUser } from "../../infrastructure/repositories/UserRepository";
import { searchType, typeToSearch } from "../../utils/search-utils";
import { isDefined } from "../../utils/type-utils";
import { AuthenticationService } from "./auth/AuthService";

const authService = new AuthenticationService();

export const getUser = async (userToSearch?: string): Promise<User> => {
    const accessToken = authService.auth.accessToken;
    if (!isDefined(userToSearch)) {
        throw new Error('Invalid value');
    }
 
    if (!isDefined(accessToken)) {
        throw new Error('Invalid token');
    }

    const searchEmail = (typeToSearch(userToSearch) === searchType.EMAIL);

    let response = (searchEmail) ? getUserByEmail(userToSearch, accessToken) : getUserById(userToSearch, accessToken);

    return response;
}

export const banUser = async (userToBan?: User): Promise<User> => {
    const accessToken = authService.auth.accessToken;

    if (!isDefined(userToBan)) {
        throw new Error('Invalid value');
    }

    if (!isDefined(accessToken)) {
        throw new Error('Invalid token');
    }

    let response = patchBanUser(userToBan.id, accessToken);

    return response;
}

export const unbanUser = async (userToUnban?: User): Promise<User> => {
    const accessToken = authService.auth.accessToken;

    if (!isDefined(userToUnban)) {
        throw new Error('Invalid value');
    }

    if (!isDefined(accessToken)) {
        throw new Error('Invalid token');
    }

    let response = patchUnbanUser(userToUnban.id, accessToken);

    return response;
}


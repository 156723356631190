import { createContext, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';
import { AlertColor, SnackbarCloseReason } from '@mui/material';
import Baklava from '../../ui/components/FloatingStuff/Baklava';

export type ToasterMessage = {
    message: string;
    key: number;
    type: AlertColor;
}

export type ToasterContextProps = {
    success: Function;
    error: Function;
    info: Function;
    warning: Function;
}

const ToasterContext = createContext<ToasterContextProps>({} as never);

export const ToasterProvider: FCWithChildren = ({children}): React.JSX.Element => {
    const [toasterPack, setToasterPack] = useState<readonly ToasterMessage[]>([]);
    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState<ToasterMessage | undefined>(undefined);

    useEffect(() => {
        if (toasterPack.length && !messageInfo) {
          setMessageInfo({ ...toasterPack[0] });
          setToasterPack((prev) => prev.slice(1));
          setOpen(true);
        } else if (toasterPack.length && messageInfo && open) {
          setOpen(false);
        }
    }, [toasterPack, messageInfo, open]);

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
      ) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    const success = (message: string) => {
        setToasterPack((prev) => [...prev, { message, key: new Date().getTime(), type: 'success' }]);
    };

    const error = (message: string) => {
        setToasterPack((prev) => [...prev, { message, key: new Date().getTime(), type: 'error' }]);
    };

    const info = (message: string) => {
        setToasterPack((prev) => [...prev, { message, key: new Date().getTime(), type: 'info' }]);
    };

    const warning = (message: string) => {
        setToasterPack((prev) => [...prev, { message, key: new Date().getTime(), type: 'warning' }]);
    };

    return (
        <ToasterContext.Provider value={{ success, error, info, warning }}>
            <Baklava
                open={open}
                onClose={handleClose}
                onExit={handleExited}
                messageInfo={messageInfo}
            />
            {children}
        </ToasterContext.Provider>
    );
};

export const useToaster = (): ToasterContextProps => useContext(ToasterContext);

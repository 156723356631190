import MainSection, {
  MainSectionProps,
} from "../../../ui/components/Layout/MainSection/MainSection";
import { FCWithChildren } from "../../../infrastructure/types/global";
import SideBar from "./SideBar/SideBar";
const drawerWidth = 320;

type ChatModLayoutProps = {
  mainSectionProps?: MainSectionProps;
};

const ChatModLayout: FCWithChildren<ChatModLayoutProps> = ({
  mainSectionProps,
  children,
}) => {
  return (
    <>
      <SideBar open={true} drawerWidth={drawerWidth} />
      <MainSection {...mainSectionProps} open={true} drawerWidth={drawerWidth}>
        {children}
      </MainSection>
    </>
  );
};

export default ChatModLayout;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./ui/pages/HomePage";
import ChatModLayout from "./ui/components/Layout/ChatModLayout";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import { AuthProvider } from "./application/context/AuthContext";
import { Error } from './helpers/error';
import UserProfilePage from "./ui/pages/UserProfilePage";
import UsersPage from "./ui/pages/UsersPage";
import AuthCallback from "./application/pages/AuthCallback";
import { ToasterProvider } from "./application/context/ToasterContext";


function App() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <ToasterProvider>
                <AuthProvider>
                <ChatModLayout>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/users" element={<UsersPage />} />
                            <Route path="/users/:userId" element={<UserProfilePage />} />
                            <Route path="/auth/callback" element={<AuthCallback />} />
                            <Route path="*" element={<Error code={404} />} />
                        </Routes>
                        </ChatModLayout>
                </AuthProvider>
                </ToasterProvider>
            </Router>
        </QueryClientProvider>
    );
}

export default App;